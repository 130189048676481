import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
})
export class LocaleDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = 'shortDate'): any {
    const datePipe: DatePipe = new DatePipe(
      this.translateService.currentLang ?? this.translateService.defaultLang
    );

    let format: undefined | string = undefined;

    if (
      (this.translateService.currentLang ??
        this.translateService.defaultLang) === 'es'
    ) {
      format = 'dd/MM/yyyy';
    } else {
      format = 'MM/dd/yyyy';
    }

    return datePipe.transform(value, format);
  }
}
