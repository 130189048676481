const translations = {
  TITLE: 'Quote Web',
  PAGES_TITLES: {
    SESSIONS: 'Sessions',
    DASHBOARD: 'Dashboard',
    PROFILE: 'Profile',
    REQUESTS: 'Requests',
    QUOTES: 'Quotes',
    ORDERS: 'Orders',
    REQUESTS_LIST: 'List',
    REQUESTS_EDIT: 'Edit',
    SESSIONS_SIGNIN: 'Sign in',
    SESSIONS_CHANGE_PASSWORD: 'Change password',
    PROFILE_SETTINGS: 'Settings',
    QUOTES_LIST: 'List',
    QUOTES_DETAIL: 'Detail',
    ORDERS_LIST: 'List',
    ORDERS_HISTORY: 'History',
    ORDERS_DETAIL: 'Detail',
    ORDERS_INVOICES_AND_CREDITS: 'Invoices and Credit Memo',
    ORDERS_LINES_LIST: 'Lines list',
  },
  ENUMS: {
    REQUEST_DOCUMENT_STATUS: {
      DRAFT: 'Draft',
      SENT: 'Sent',
      ACCEPTED: 'Accepted',
    },
    REQUEST_DOCUMENT_TYPE: {
      NORMAL: 'Normal',
      URGENT: 'Urgent',
      VERY_URGENT: 'Very urgent',
    },
    QUOTE_DOCUMENT_STATUS: {
      PENDING_SHIPPING: 'Pending shipping',
      PENDING_CLARIFICATION: 'Pending clarification',
      PENDING_CONFIRMATION: 'Pending confirmation',
      PARTIALLY_ACCEPTED: 'Partially accepted',
      ACCEPTED: 'Accepted',
      REJECTED: 'Rejected',
      ANNULLED: 'Annulled',
    },
    QUOTE_APPROVAL_STATUS: {
      PRELIMINARY_REVIEW: 'Preliminary review',
      NOT_APPROVAL_NEEDED: 'No approval needed',
      PENDING: 'Pending',
      APPROVED: 'Approved',
      NOT_APPROVED: 'Not approved',
    },
    LINE_STATUS: {
      PENDING_CLARIFICATION: 'Pending clarification',
      PENDING_CONFIRMATION: 'Pending confirmation',
      ACCEPTED: 'In order',
      REJECTED: 'Annulled',
      ACCEPTED_FROM_PORTAL: 'Acepted',
      REJECTED_FROM_PORTAL: 'Rejected',
    },
    ORDER_DOCUMENT_STATUS: {
      PENDING_SHIPPING: 'Pending shipping',
      PENDING_CLARIFICATION: 'Pending clarification',
      SERVE_PENDING: 'Serve pending',
      DELIVERED: 'Delivered',
      PARTIALLY_DELIVERED: 'Partially delivered',
      SHIPPED: 'Shipped',
      PARTIALLY_SHIPPED: 'Partially shipped',
      INVOICED: 'Invoiced',
    },
    ORDER_STATUS_DEADLINE: {
      WITHOUT_DEADLINE: 'Without deadline',
      OUT_OF_DEADLINE: 'Out of deadline',
      ESTIMATED_DEADLINE: 'Estimated deadline',
      CONFIRMED_DEADLINE: 'Confirmed deadline',
    },
  },
  SNACKBAR: {
    ERROR: 'Error',
    SUCCESS: 'Success',
    WARNING: 'Warning',
    MESSAGES: {
      ERROR: 'An error occurred',
      SUCCESS: 'Operation was successful',
      WARNING: 'Warning',
    },
    DETAILED_MESSAGES: {
      INTERNAL_SERVER_ERROR: 'Internal server error',
      REQUEST_ERROR: 'An error occurred while making the request',
      ERROR_CONNECTION_LOST: 'Connection with the server has been lost!',
      WRONG_CREDENTIALS: 'Incorrect user or password',
      REQUEST_NOT_FOUND: 'Request not found!',
      ERROR_ON_ADD_REQUEST: 'An error occurred while adding the request!',
      SUCCESS_ON_ADD_REQUEST: 'Request added!',
      ERROR_ON_UPDATE_REQUEST: 'An error occurred while updating the request!',
      SUCCESS_ON_UPDATE_REQUEST: 'Request updated!',
      SUCCESS_ON_ADD_REQUEST_ITEM: 'A product has been added!',
      SUCCESS_ON_UPDATE_REQUEST_ITEM: 'A product has been updated!',
      SUCCESS_ON_SEND_REQUEST: 'Request sent successfully',
      ERROR_ON_SEND_REQUEST: 'Request could not be sent',
      SUCCESS_ON_DELETE_REQUEST: 'Request deleted successfully',
      ERROR_ON_DONWLOAD_QUOTE_LIST_FILE:
        'The selected file could not be downloaded',
      SUCCESS_ON_SEND_ACCEPTANCE_QUOTE_TO_APPROVER:
        'We have received acceptance of the quote, when the approving user approves it, you will soon be able to consult your order in the orders in progress section',
      ERROR_ON_SEND_ACCEPTANCE_QUOTE:
        'The quote could not be accepted, please try again',
      SUCCESS_ON_SEND_ACCEPTANCE_QUOTE:
        'We have received acceptance of the quote, you will soon be able to check your order in the orders in progress section',
      SUCCESS_ON_MODIFY_CECO: 'CECO modified correctly',
      ERROR_ON_MODIFY_CECO_VALUE: 'Error when modifying Ceco: {{value}}',
      ERROR_ON_MODIFY_CECO: 'Error modifying Ceco, try again',
      SUCCESS_ON_APPROVE_QUOTE: 'We have received approval of the quote',
      ERROR_ON_APPROVE_QUOTE:
        'The quote could not be approved, please try again',
      SUCCESS_ON_NOT_APPROVE_QUOTE:
        'We have received non-approval of the quote',
      ERROR_ON_NOT_APPROVE_QUOTE:
        'The quote could not be disapproved, please try again',
      ORDER_NOT_FOUND: 'Order not found!',
      ERROR_ON_DOWNLOAD_INVOICE_CREDIT_MEMO:
        'Error downloading invoice/credit memo',
      ERROR_REQUEST_ITEM_IS_BLOCKED:
        'A product from the request is blocked, call your manager',
    },
    ACTIONS: {
      OK: 'Okey',
      CLOSE: 'Close',
    },
  },
  COMPONENTS: {
    AG_GRID_NO_ROWS: 'No rows to show',
    POPUP_MODIFY_CECO: {
      LABEL: 'Ceco selected: {{value}}',
      SEARCH_INPUT: 'Search ceco',
      TABLE: {
        HEADERS: {
          CODE: 'Code',
          DESCRIPTION: 'Description',
        },
        BLANK_RECORD: 'Empty',
      },
      ACTIONS: {
        ACCEPT: 'Accept',
        CANCEL: 'Cancel',
      },
    },
  },
  SERVICES: {
    CONFIRM: {
      ACTIONS: {
        ACCEPT: 'Accept',
        CANCEL: 'Cancel',
      },
      DEFAULT_VALUES: {
        TITLE: 'Confirmation',
        MESSAGE: 'Are you sure?',
        INPUT_TEXT: 'Order number (if you have)',
      },
    },
    LOADER: {
      TITLE: 'Please wait',
    },
  },
  AUTH: {
    SIGNIN: {
      TITLE: 'Access to the customer area',
      SIGNIN_BTN: 'Access',
      USERNAME: 'User',
      PASSWORD: 'Password',
    },
    CHANGE_PASSWORD: {
      TITLE: 'Enter the information required to change your password',
      FORM: {
        LABELS: {
          CURRENT_PASSWORD: 'Current password',
          NEW_PASSWORD: 'New password',
          REPEAT_NEW_PASSWORD: 'Repeat new password',
        },
        ERRORS: {
          NEW_PASSWORD_REQUIRED: 'New password is required',
          REPEAT_NEW_PASSWORD_REQUIRED: 'Repeat new password is required',
          REPEAT_NEW_PASSWORD_MISMATCH: 'Passwords do not match',
        },
        BUTTONS: {
          CHANGE: 'Change',
          CANCEL: 'Cancel',
        },
      },
      SNACKBAR: {
        wrongPassword:
          'Current password must be the same password that the user has today',
        newPasswordNotMatch:
          'New password and repeat password must be the same',
      },
    },
  },
  PROFILE: {
    INFO: {
      TITLE: 'User information',
      LABELS: {
        NAME: 'Name',
        LAST_NAME: 'Last name',
        EMAIL: 'Email',
        PHONE: 'Phone',
        LANGUAGE: 'Language',
      },
      DEFAULT_LABELS: {
        LANGUAGE: 'Default language',
      },
    },
  },
  HEADER: {
    USER_MENU: {
      SIGN_OUT: 'Exit',
      PROFILE: 'Profile',
      CHANGE_PASSWORD: 'Change password',
    },
    HELP_MENU: {
      DOWNLOAD_MANUAL: 'Download manual',
    },
    BUTTONS: {
      NEW_REQUEST: 'New request',
      UPLOAD_MANUAL: 'Upload manual',
    },
    NAVIGATION: {
      HOME: 'Home',
      NEW_REQUEST: 'New request',
      REQUESTS_IN_PROGRESS: 'Requests in progress',
      QUOTES_IN_PROGRESS: 'Quotes in progress',
      ORDERS_IN_PROGRESS: 'Orders in progress',
      ORDER_HISTORY: 'Order history',
      INVOICE_HISTORY: 'Invoice history',
      ORDER_LINES: 'Order lines',
    },
    TOOLTIPS: {
      HELP_MENU: 'Help',
      USER_MENU: 'Account settings',
      BUTTON_NEW_REQUEST: 'Open a new request',
      BUTTON_UPLOAD_MANUAL: 'Attach manual',
      BUTTON_TOGGLE_MENU: 'Toggle hide/show',
    },
  },
  HOME: {
    WELCOME_TITLE: 'Welcome, {{name}}',
    TILES: {
      TITLES: {
        DOCUMENTS: 'Documents',
        APPROVALS: 'Approvals',
      },
      DOCUMENTS: {
        QUOTE_REQUESTS: 'Quote Requests',
        QUOTES_IN_PROGRESS: 'Quotes in progress',
        ORDERS_IN_PROGRESS: 'Orders in progress',
      },
      APPROVALS: {
        QUOTES_PENDING_APPROVAL: 'Your quotes pending approval',
        TO_APPROVE: 'To approve',
      },
    },
  },
  REQUEST: {
    EDIT_REQUEST: {
      TITLE: 'Request for quote',
      SUBTITLE: 'Request information: {{value}}',
      BUTTONS: {
        BACK: 'Back',
        SEND: 'Send request',
        SAVE: 'Save request',
        DELETE: 'Delete request',
        ADD_ITEM: 'Add products',
      },
      FORM: {
        LABELS: {
          DATE: 'Date',
          REQUEST_TYPE: 'Request type',
          COMPANY: 'Company',
          USER_REQUESTING: 'User requesting',
          YOUR_REFERENCE: 'Your reference',
          CECO: 'Ceco',
          REPAIR: 'Repair',
          COMMENT: 'Comment',
        },
        CONTROLS: {
          MIN_LENGTH: 'This field requires at least {{value}} characters',
          MAX_LENGTH:
            'This field cannot contain more than {{value}} characters',
        },
        TITLES: {
          OPEN_CECO_LIST: 'Open CECO list',
        },
      },
      TABLE: {
        TITLE: 'List of products',
        TOTAL_IMPORT: 'Total: ',
        ACTIONS: {
          MODIFY: 'Modify',
          DELETE: 'Delete',
          SEE: 'See',
        },
        HEADERS: {
          CUSTOMER_REFERENCE: 'Customer reference',
          DESCRIPTION: 'Material/Service',
          UNIT_PRICE: 'Unit price',
          QUANTITY: 'Quantity',
          TOTAL: 'Total',
          FILES: 'Files',
          ACTIONS: 'Actions',
        },
      },
      DIALOGS: {
        ITEM_PTPC: {
          ADD_ITEMS: 'Add products',
          UPDATE_ITEMS: 'Update products',
          SEE_ITEMS: 'See products',
        },
        SEND_REQUEST: {
          MESSAGE: 'Do you confirm that you want to submit the request?',
          TITLE: 'Request submission',
        },
        DELETE_REQUEST: {
          MESSAGE: 'Do you confirm that you want to delete the request?',
          TITLE: 'Delete request',
        },
        DELETE_REQUEST_ITEM: {
          MESSAGE: 'Do you confirm that you want to delete the product?',
          TITLE: 'Delete product',
        },
      },
    },
    EDIT_REQUEST_DETAIL: {
      FORM: {
        LABELS: {
          DESCRIPTION_ITEM: 'Description',
          QUANTITY: 'Quantity',
          UNIT_PRICE: 'Unit price',
          ALLOW_ALTERNATIVES: 'Allow alternatives',
        },
        BUTTONS: {
          OPEN_CATALOG_TOOL_TIP: 'Open product catalog',
        },
      },
      FILES: {
        ATTACH_FILE: 'Attach file',
        MAX_SIZE: '({{value}} Maximum size)',
        NO_FILES: 'No files attached',
        PROGRESS: 'Progress:',
        TOOL_TIPS: {
          DELETE: 'Delete file',
          DOWNLOAD: 'Download file',
        },
        ON_UPLOAD_ERRORS: {
          ERROR: 'Error uploading file {{value}}',
          MAX_SIZE:
            'Careful! File "{{fileName}}" is {{size}}, it exceeds the maximum allowed size of {{maxSize}}',
        },
        ON_DELETE_FILE: {
          MESSAGE: 'Do you confirm that you want to delete the file?',
          TITLE: 'Delete file',
        },
      },
      DIALOG_ACTIONS: {
        ACCEPT: 'Accept',
        CANCEL: 'Cancel',
      },
    },
    ITEM_PTPC: {
      LABEL: 'Product selected: {{value}}',
      SEARCH_INPUT: 'Search product',
      TABLE: {
        HEADERS: {
          CODE: 'Code',
          DESCRIPTION: 'Description',
          VENDOR_REFERENCE: 'Vendor reference',
        },
      },
      ACTIONS: {
        ACCEPT: 'Accept',
        CANCEL: 'Cancel',
      },
    },
    LIST_REQUEST: {
      TITLE: 'Quote Requests',
      SEARCH_INPUT: 'Search request',
      TABLE: {
        REQUEST_NO: 'Request No.',
        CECO: 'Ceco',
        COMPANY: 'Company',
        DATE: 'Date',
        REQUESTED_BY: 'Requested by',
        EXTERNAL_REFERENCE: 'External reference',
        STATUS: 'Status',
        IMPORT: 'Import',
      },
    },
  },
  QUOTE: {
    LIST: {
      TITLE: 'Quote',
      SEARCH_INPUT: 'Search quote',
      CLEAN_FILTERS: 'Clean filters',
      TABLE: {
        QUOTE_NO: 'Quote No.',
        CECO: 'Ceco',
        COMPANY: 'Company',
        REQUEST_DATE: 'Request Date',
        REQUESTED_BY: 'Requested by',
        EXTERNAL_REFERENCE: 'External reference',
        QUOTE_STATUS: 'Quote Status',
        APPROVAL_STATUS: 'Approval Status',
        IMPORT: 'Import',
        DOWNLOAD: 'Download',
      },
      TABLE_ACTIONS: {
        DOWNLOAD: 'Download',
      },
    },
    DETAIL: {
      TITLE: 'Quote',
      BUTTONS: {
        APPROVE_THE_QUOTE: 'Approve the quote',
        NOT_APPROVE_THE_QUOTE: 'Not approve the quote',
        MODIFY_CECO: 'Modify CECO',
        BACK: 'Back',
        ACCEPT_QUOTE: 'Accept quote',
      },
      TOOL_TIPS: {
        APPROVE_THE_QUOTE: 'Approve the quote',
        NOT_APPROVE_THE_QUOTE: 'Not approve the quote',
        MODIFY_CECO: 'Modify CECO',
        BACK: 'Go to the previous page',
      },
      LABELS: {
        QUOTE_NO: 'Quote No.',
        REQUESTED_BY: 'Requested by',
        YOUR_REFERENCE: 'Your reference',
        STATUS: 'Status',
        CECO: 'Ceco',
        APPROVAL_STATUS: 'Approval status',
        DIRECT_ORDER: 'Direct order',
        COMMENT: 'Comment',
      },
      TABLE: {
        TITLE: 'List of products',
        ACTIONS: {
          YES: 'Yes',
          NO: 'No',
        },
        HEADERS: {
          CUSTOMER_REFERENCE: 'Customer reference',
          DESCRIPTION: 'Material/Service',
          QUANTITY: 'Quantity',
          IMPORT: 'Import',
          STATUS: 'Status',
          ACCEPTED_LINE: 'Accepted line',
        },
        TOTAL_IMPORT: 'Total: ',
      },
      DIALOGS: {
        ACCEPT: {
          TITLE: 'Accept quote',
          MESSAGE: 'Do you want to accept the quote?',
          INPUT_TEXT: 'Order number (if you have)',
        },
        SELECT_APPROVER: {
          TITLE: 'Select the approver user',
          MESSAGE:
            'Notice: This quote requires internal approval for processing. Approval request is sent to the user:',
          INPUT_TEXT: 'Approver user',
        },
        APPROVE: {
          TITLE: 'Approve quote',
          MESSAGE: 'Do you want to approve the quote?',
        },
        NOT_APPROVE: {
          TITLE: 'Do not approve quote',
          MESSAGE: 'Do you want to not approve the quote?',
        },
      },
    },
  },
  ORDER: {
    ORDER_LIST: {
      TITLE: 'Orders',
      HISTORY_TITLE: 'Order history',
      SEARCH_INPUT: 'Search order',
      TABLE: {
        HEADERS: {
          ORDER_NO: 'Order No.',
          CECO: 'Ceco',
          COMPANY: 'Company',
          ORDER_DATE: 'Order date',
          REQUESTED_BY: 'Requested by',
          EXTERNAL_REFERENCE: 'External reference',
          STATUS: 'Status',
          EXTERNAL_DOCUMENT_NO: 'External document No.',
          IMPORT: 'Import',
          QUOTE: 'Quote',
        },
      },
    },
    DETAIL: {
      TITLE: 'Order',
      BUTTONS: {
        BACK: 'Back',
      },
      LABELS: {
        ORDER_NO: 'Order No.',
        ORDER_DATE: 'Order date',
        REQUEST_NO: 'Request No.',
        CECO: 'Ceco',
        QUOTE_NO: 'Quote No.',
        EXTERNAL_DOCUMENT_NO: 'External document No.',
        EXTERNAL_REFERENCE: 'External reference',
        COMMENT: 'Comment',
      },
      TABLE: {
        TITLE: 'List of products',
        HEADERS: {
          CUSTOMER_REFERENCE: 'Customer reference',
          DESCRIPTION: 'Material/Service',
          QUANTITY: 'Quantity',
          QUANTITY_SENT: 'Quantity sent',
          OUTSTANDING_QUANTITY: 'Outstanding quantity',
          IMPORT: 'Import',
          STATUS: 'Status',
          STATUS_DEADLINE: 'Status deadline',
          EXPECTED_DEADLINE_DATE: 'Expected deadline date',
        },
        TOTAL_IMPORT: 'Total: ',
      },
    },
    INVOCIE_CREDIT_MEMO: {
      TITLE: 'Invoices and Credit Memo',
      SEARCH_INPUT: 'Search invoice/credit memo',
      TABLE: {
        INVOICE_CREDIT_NO: 'Invoice/Credit memo No.',
        DATE: 'Date',
        IMPORT: 'Import',
        TYPE: 'Type',
        DOWNLOAD: 'Download',
      },
      TABLE_ACTIONS: {
        DOWNLOAD: 'Download',
      },
      LINE_TYPE: {
        INVOICE: 'Invoice',
        CREDIT_MEMO: 'Credit memo',
      },
    },
    ORDER_LINES_LIST: {
      TITLE: 'Order lines',
      SEARCH_INPUT: 'Search order line',
      TABLE: {
        HEADERS: {
          ORDER_DATE: 'Order date',
          CLARIFICATION_ORDER_DATE: 'Clarification order date',
          QUOTE_NO: 'Quote No.',
          ORDER_NO: 'Order No.',
          COMPANY: 'Company',
          EXTERNAL_REFERENCE: 'External reference',
          EXTERNAL_DOCUMENT_NO: 'External document No.',
          CUSTOMER_REFERENCE: 'Customer reference',
          DESCRIPTION: 'Material/Service',
          REQUESTED_QTY: 'Requested qty',
          SHIPPED_QTY: 'Shipped qty',
          OUTSTANDING_QTY: 'Outstanding qty',
          EXPECTED_DELIVERY_DATE: 'Expected delivery date',
          STATUS_DEADLINE: 'Status deadline',
          WORK_TYPE: 'Reason pending deadline',
          SELL_TO_CONTACT: 'Requester',
          CECO: 'Ceco',
        },
      },
      EXCEL: {
        ORDER_DATE: 'Order date',
        CLARIFICATION_ORDER_DATE: ' Clarification order date',
        QUOTE_NO: 'Quote No.',
        ORDER_NO: 'Order No.',
        COMPANY: 'Company',
        EXTERNAL_REFERENCE: 'External reference',
        EXTERNAL_DOCUMENT_NO: 'External document No.',
        CUSTOMER_REFERENCE: 'Customer reference',
        DESCRIPTION: 'Material/Service',
        REQUESTED_QTY: 'Requested qty',
        SHIPPED_QTY: 'Shipped qty',
        OUTSTANDING_QTY: 'Outstanding qty',
        EXPECTED_DELIVERY_DATE: 'Expected delivery date',
        STATUS_DEADLINE: 'Status deadline',
        WORK_TYPE: 'Reason pending deadline',
        SELL_TO_CONTACT: 'Requester',
        CECO: 'Ceco',
      },
    },
  },
};

export default translations;
